import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckSessionService {

  constructor() { }

  checkSessionRut(){
      if (sessionStorage.getItem("rut") === "" || sessionStorage.getItem("rut") === null){
        return false;
      }
      return true;
    }

  checkSession(){
    if (sessionStorage.getItem("cor") === "" || sessionStorage.getItem("cor") === null || sessionStorage.getItem("tel") === "" || sessionStorage.getItem("tel") === null || !this.checkSessionRut()){
      return false;
    }
    return true;
  }
}
