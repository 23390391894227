import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CustomTtpService {

  constructor() { }

  ntFnctnDcrpt(texto:string ){
    return CryptoJS.AES.decrypt(texto.trim(), 'TTP20210108Santiago').toString(CryptoJS.enc.Utf8);
  }
  ntFnctnCrpt(text:string){
    let key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f101112131415161718191a1b1c1d1e1f');
    let encrypted = CryptoJS.AES.encrypt(text, key, { mode: CryptoJS.mode.ECB });
    return encrypted.toString();
  }
}

