import { Component, OnInit, TemplateRef } from '@angular/core';
import { AgendarService } from '../services/agendar.service';
import { SnackalertService } from '../directives/snackalert/service/snackalert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckSessionService } from '../services/check-session.service';
import { Router } from '@angular/router';
import { TitleService } from '../services/title.service';

@Component({
  selector: 'app-hor-can',
  templateUrl: './hor-can.component.html',
  styleUrls: ['./hor-can.component.css'],
})
export class HorCanComponent implements OnInit {
  reservas = [];
  isModalActive: boolean = false;
  eliminarResPos: number = -1;

  constructor(
    private agendarService: AgendarService,
    private snackalertService: SnackalertService,
    private ngxSpinnerService: NgxSpinnerService,
    private sessionCheck: CheckSessionService,
    private route: Router,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Listado de reservas');
    if (!this.sessionCheck.checkSessionRut()) {
      this.route.navigate(['']);
    } else {
      this.listarReservas();
    }
  }

  async listarReservas() {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.listarReservas(
      sessionStorage.getItem('rut')
    );
    console.log(response);
    this.reservas = new Array();
    if (response['info'].coderr === '7777' || response['info'].coderr === '0') {
      if (response['listarReservas'].length >= 1) {
        for (let i = 0; i < response['listarReservas'].length; i++) {
          this.reservas.push({
            idOficina: response['listarReservas'][i].idOficina,
            idSerie: response['listarReservas'][i].idSerie,
            oficina: response['listarReservas'][i].oficina,
            serie: response['listarReservas'][i].serie,
            dia: response['listarReservas'][i].dia,
            hora: response['listarReservas'][i].hora,
            ok: response['listarReservas'][i].fok === 1 ? true : false,
            pos: i,
          });
        }
        console.log(this.reservas);
      } else {
        this.snackalertService.info(
          'RUT sin reservas'
        );
      }
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    this.ngxSpinnerService.hide();
  }

  async anularReservas(pos: number) {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.anularReserva(
      sessionStorage.getItem('rut'),
      this.reservas[pos].idSerie,
      this.reservas[pos].idOficina,
      this.reservas[pos].dia,
      this.reservas[pos].hora
    );
    console.log(response);
    this.reservas = new Array();
    if (response['info'].coderr === '0') {
      this.snackalertService.success('Reserva anulada correctamente');
      this.toggleModal(-1);
      this.listarReservas();
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      this.toggleModal(-1);
      this.listarReservas();
      this.ngxSpinnerService.hide();
      console.log(response);
    }
  }
  eliminarReserva() {
    this.anularReservas(this.eliminarResPos);
  }
  toggleModal(pos: number) {
    this.eliminarResPos = pos;
    this.isModalActive = !this.isModalActive;
  }
}
