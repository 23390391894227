import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from '../../services/title.service';
import { AppConfig } from './../../app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  subTitle: string = '';
  bMostrar = true;
  urlLogo: string;
  
  constructor(private config: AppConfig, private router: Router, private titleService: TitleService) {
    this.urlLogo = this.config.get('urlLogo');
  }

  ngOnInit(): void {
    this.titleService.title.subscribe((value) => {
      this.subTitle = value;
      if (value === 'Bienvenido al agendamiento de atención en sucursal' || value === 'Datos de su reserva') {
        this.bMostrar = false;
      } else {
        this.bMostrar = true;
      }
    });
  }

  inicio() {
    this.router.navigate(['']);
  }
}
