import { AppConfig } from './../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
declare const Encoder : any;


@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(
    private config: AppConfig,
    private httpClient: HttpClient,
    private token: TokenService
  ) {}

  async sendEmail(email: string) {
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')
      
    });
    const apiUrl = await this.config.get('apiUrl');
    let fec = sessionStorage.getItem('fec').split('-');
    let emailBody = await this.config.get('emailBody');
    emailBody = emailBody.replace('XXXOFI', sessionStorage.getItem('Oficina'))
                         .replace('XXXSER', sessionStorage.getItem('Serie'))
                         .replace('XXXDIA', `${ fec[2] }-${ fec[1] }-${ fec[0] }`)
                         .replace('XXXHORA', sessionStorage.getItem('hor'));
    
    console.log(Encoder.htmlEncode(emailBody));
    let body: Object = {
      'destinatario' : email,
      'mensaje'   : Encoder.htmlEncode(emailBody),
    };
    console.log(body);
    try {
      return await this.httpClient.post(apiUrl + 'Email', body, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }

}
