export class SnackAlert {
    type: SnackAlertType;
    message: string;
}

export enum SnackAlertType {
    Success,
    Error,
    Info,
    Warning
}
