import { Component, OnInit } from '@angular/core';
import { AgendarService } from '../services/agendar.service';
import { SnackalertService } from '../directives/snackalert/service/snackalert.service';
import { FormGroup, FormControl } from '@angular/forms';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { listLocales } from 'ngx-bootstrap/chronos';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckSessionService } from '../services/check-session.service';
import { TitleService } from '../services/title.service';
import { EmailService } from '../services/email.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

defineLocale('es', esLocale);

@Component({
  selector: 'app-par-sel',
  templateUrl: './par-sel.component.html',
  styleUrls: ['./par-sel.component.css'],
})
export class ParSelComponent implements OnInit {
  //arrays de data traida desde el servidor
  oficinas: any[] = new Array();
  series: any[] = new Array();
  fechas: any[] = new Array();
  horas: any[] = new Array();

  bsConfig: Partial<BsDatepickerConfig>;

  filterForm: FormGroup;
  oficina = '';
  serie = '';
  fecha = '';
  hora = '';

  bSerie = false;
  bFecha = false;
  bHora = false;
  bAgendar = false;
  bCal = false;
  bOK = true;
  bSerieSel = false;
  bOfiSel = false;


  //calendario
  currentDate = new Date();
  locale = 'es';
  locales = listLocales();
  enabledDates = [];


  constructor(
    private agendarService: AgendarService,
    private snackalertService: SnackalertService,
    private localeService: BsLocaleService,
    private datepipe: DatePipe,
    private router: Router,
    private sessionCheck: CheckSessionService,
    private titleService: TitleService,
    private ngxSpinnerService: NgxSpinnerService,
    private emailService : EmailService
  ) {
    this.filterForm = new FormGroup({
      oficinas: new FormControl(''),
      series: new FormControl(''),
      fecha: new FormControl(''),
      hora: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Agenda tu atención en sucursal');
    sessionStorage.removeItem('Oficina');
    sessionStorage.removeItem('Serie');
    sessionStorage.removeItem('Turno');
    sessionStorage.removeItem('ofi');
    sessionStorage.removeItem('ser');
    sessionStorage.removeItem('fec');
    sessionStorage.removeItem('hor');
    if (!this.sessionCheck.checkSessionRut()) {
      this.router.navigate(['']);
    } else {
      this.listarOficinas();
    }
  }
  async listarOficinas() {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.listarOficinas();
    this.oficinas = new Array();
    if (response['info'].coderr === '0') {
      for (let i = 0; i < response['listoficinas'].length; i++) {
        this.oficinas.push(response['listoficinas'][i]);
      }
      this.bOfiSel = this.oficinas.length === 1 ? true : false;
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    this.ngxSpinnerService.hide();
    if (this.bOfiSel) {
      this.onChangeSelection(this.oficinas[0]['idOfi'], 'Ofi');
    }

  }

  async listarSeries(idOfi: string) {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.listarSeries(idOfi);
    this.series = new Array();
    console.log(response);
    if (response['info'].coderr === '0') {
      if (response['listarseries'].length >= 1) {
        for (let i = 0; i < response['listarseries'].length; i++) {
          this.series.push(response['listarseries'][i]);
        }
        this.bSerieSel = this.series.length === 1 ? true : false;
      }
      console.log(this.series);
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    this.ngxSpinnerService.hide();
    if (this.bSerieSel) {
      this.onChangeSelection(this.series[0]['idSerie'], 'Ser');
    }

  }

  async listarDias(idOfi: string, idSer: string) {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.listarDias(idOfi, idSer);
    this.enabledDates = new Array();
    console.log(response['listardias']);
    if (response['info'].coderr === '0') {
      if (response['listardias'].length >= 1) {
        for (let i = 0; i < response['listardias'].length; i++) {
          if (response['listardias'][i].ok === 1) {
            this.enabledDates.push(
              new Date(response['listardias'][i].dia.replace(/-/g, '/'))
            );
          }
        }
      }
      this.generaDias();
      this.bFecha = true;
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    this.ngxSpinnerService.hide();
  }

  async listarHoras(idOfi: string, idSer: string, fecha: string) {
    this.ngxSpinnerService.show();
    let response = await this.agendarService.listarHoras(idOfi, idSer, fecha);
    if (response['info'].coderr === '0') {
      if (response['listarHoras'].length >= 1) {
        this.horas = new Array();
        for (let i = 0; i < response['listarHoras'].length; i++) {
          this.horas.push({
            hora: response['listarHoras'][i].hora,
            ok: response['listarHoras'][i].ok === 1 ? true : false,
          });
        }
      }
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    this.bHora = true;
    this.ngxSpinnerService.hide();
  }

  onChangeSelection(val, option) {
    switch (option) {
      case 'Ofi': {
        this.bSerie = val === '' ? false : true;
        this.bFecha = false;
        this.bHora = false;
        this.bAgendar = false;
        sessionStorage.setItem('ofi', val);
        sessionStorage.removeItem('ser');
        sessionStorage.removeItem('fec');
        sessionStorage.removeItem('hor');
        if (val !== '') {
          this.listarSeries(val);
        } else {
          sessionStorage.removeItem('ofi');
        }

        break;
      }
      case 'Ser': {
        this.bHora = false;
        this.bAgendar = false;
        sessionStorage.setItem('ser', val);
        sessionStorage.removeItem('fec');
        sessionStorage.removeItem('hor');
        if (val !== '') {
          this.listarDias(sessionStorage.getItem('ofi'), val);
        } else {
          sessionStorage.removeItem('ser');
        }
        break;
      }
      case 'Fec': {
        this.bHora = false;
        this.bAgendar = false;

        if (val !== '') {
          let fecha = this.datepipe.transform(val, 'yyyy-MM-dd');
          sessionStorage.setItem('fec', fecha);
          sessionStorage.removeItem('hor');
          this.listarHoras(
            sessionStorage.getItem('ofi'),
            sessionStorage.getItem('ser'),
            fecha
          );
        } else {
          sessionStorage.removeItem('fec');
        }
        break;
      }
      case 'Hor': {
        this.bAgendar = val === '' ? false : true;
        if (val !== '') {
          sessionStorage.setItem('hor', val);
        } else {
          sessionStorage.removeItem('hor');
        }
        break;
      }
    }
  }

  generaDias() {
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig = Object.assign(
      {},
      {
        locale: 'es',
        containerClass: 'theme-blue',
        datesEnabled: this.enabledDates,
        dateInputFormat: 'DD/MM/YYYY',
      }
    );
  }

  agendar() {
    this.ngxSpinnerService.show();
    this.agendarHora();
    this.ngxSpinnerService.hide();
  }

  async agendarHora() {
    let ok = 'NOK';
    this.bOK = false;
    let response = await this.agendarService.agendar(
      sessionStorage.getItem('ofi'),
      sessionStorage.getItem('ser'),
      sessionStorage.getItem('fec'),
      sessionStorage.getItem('hor'),
      sessionStorage.getItem('rut'),
      sessionStorage.getItem('cor'),
      sessionStorage.getItem('tel')
    );
    console.log(response);
    if (response['info'].coderr === '0') {
      ok = 'OK';
      sessionStorage.setItem('Oficina', response['oficina']);
      sessionStorage.setItem('Serie', response['serie']);
      sessionStorage.setItem('Turno', response['turno']);
      this.snackalertService.success('Agenda creada correctamente');
      this.emailService.sendEmail(sessionStorage.getItem('cor'));
      setTimeout(() => {
        this.router.navigate(['/horsel']);
      }, 1200);
    } else if (response['info'].coderr === '8888') {
      this.snackalertService.error(response['info'].deserr);
      this.onChangeSelection('', 'Fec');
    } else {
      this.snackalertService.error(
        'Ups!, hemos tenido un problema con el servidor, por favor intentelo mas tarde'
      );
      console.log(response);
    }
    setTimeout(() => {
      this.bOK = true;
    }, 1200);
  }
  
  inicio() {
    this.router.navigate(['']);
  }
}
