import { AppConfig } from './app.config';
import { SnackalertService } from 'src/app/directives/snackalert/service/snackalert.service';

import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Translate and locale
import { LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { ParSelComponent } from './par-sel/par-sel.component';
import { HorSelComponent } from './hor-sel/hor-sel.component';
import { HorCanComponent } from './hor-can/hor-can.component';
import { SnackalertComponent } from './directives/snackalert/snackalert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomValidator } from './validators/custom.validator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';

//ngx-bootstrap locale
defineLocale('es', esLocale);
registerLocaleData(localeEs, 'es');
const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { header_title: 'Ingrese sus datos' },
  },
  { path: 'parsel', component: ParSelComponent },
  { path: 'horsel', component: HorSelComponent },
  { path: 'horcan', component: HorCanComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ParSelComponent,
    HorSelComponent,
    HorCanComponent,
    SnackalertComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
  ],
  providers: [
    SnackalertService,
    AppConfig,
    CustomValidator,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
