import { Component, OnInit } from '@angular/core';

import { SnackAlert, SnackAlertType } from './model/snackalert/snackalert.model';
import { SnackalertService } from './service/snackalert.service';

@Component({
  selector: 'snackalert',
  templateUrl: './snackalert.component.html',
  styleUrls: ['./snackalert.component.css']
})
export class SnackalertComponent implements OnInit {

  alerts: SnackAlert[] = [];

    constructor(private snackalertService: SnackalertService) { }

    ngOnInit() {
        this.snackalertService.getAlert().subscribe((alert: SnackAlert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }

            // add alert to array
            this.alerts.push(alert);
        });
    }

    removeAlert(alert: SnackAlert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: SnackAlert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case SnackAlertType.Success:
                return 'snackbar show success';
            case SnackAlertType.Error:
                return 'snackbar show danger';
            case SnackAlertType.Info:
                return 'snackbar show info';
            case SnackAlertType.Warning:
                return 'snackbar show warning';
        }
    }

}
