<!-- <div class="title"> 
    Listado de Reservas
</div> -->

<div class="columns is-multiline is-mobile my-4">
    <!-- <div class="column is-hidden-mobile">
        <img src="assets/img/agendamiento_totalpack.svg" class="margin-img img-home">
    </div> -->
    
    <div class="column is-narrow is-pulled-right" *ngFor="let res of reservas">
        <div class="box">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="labelsLeft">Oficina</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <input class="input is-static labelsRight" type="email" value="{{ res.oficina }}" readonly />
                        </p>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal pr-4">
                    <label class="labelsLeft">Serie</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <input class="input is-static labelsRight" type="email" value="{{ res.serie }}" readonly />
                        </p>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal pr-2">
                    <label class="labelsLeft">Fecha</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <input class="input is-static labelsRight" type="email" value="{{ res.dia | date: 'dd-MM-yyyy' }}" readonly />
                        </p>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label is-normal pr-4">
                    <label class="labelsLeft">Hora</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <p class="control">
                            <input class="input is-static labelsRight" type="email" value="{{ res.hora }}" readonly />
                        </p>
                    </div>
                </div>
            </div>
            <div class="field">
                <div class="buttons is-centered">
                    <button type="button" class="button is-link is-light" (click)="toggleModal(res.pos)" [disabled]="!res.ok">
            Cancelar Reserva
          </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal centered" [ngClass]="{ 'is-active': isModalActive }">
        <div class="has-text-centered">
            <div class="modal-background" (click)="toggleModal(-1)"></div>
            <div class="modal-card">
                <header class="modal-card-head centered">
                    <p class="modal-card-title">¿Desea cancelar su reserva?</p>
                    <button class="delete" aria-label="close" (click)="toggleModal(-1)"></button>
                </header>
            </div>
            <footer class="modal-card-foot buttons is-centered">
                <button class="button is-info" (click)="eliminarReserva()">
          Si
        </button>
                <button class="button is-link is-light" (click)="toggleModal(-1)">
          No
        </button>
            </footer>
        </div>
    </div>
</div>