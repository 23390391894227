import { AppConfig } from './../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service'

@Injectable({
  providedIn: 'root'
})
export class AgendarService {

  constructor(private config: AppConfig,
              private httpClient: HttpClient,
              private token: TokenService) { }

  async listarOficinas(){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    //const apiUrl = "/posts/";

    try {
      return await this.httpClient.get(apiUrl + 'Oficinas', {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }


  async listarSeries(idOfi: string){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    try {
      return await this.httpClient.get(apiUrl + `Series?idoficina=${idOfi}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }

  async listarDias(idOfi: string, idSer: string){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    try {
      return await this.httpClient.get(apiUrl + `Dias?idoficina=${idOfi}&idserie=${idSer}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }

  async listarHoras(idOfi: string, idSer: string, fecha: string){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    try {
      return await this.httpClient.get(apiUrl + `Horas?idoficina=${idOfi}&idserie=${idSer}&dias=${fecha}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }


  async agendar(idOfi: string, idSer: string, fecha: string, hora: string, rut: string, mail: string, fono: string ){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    let body: Object = {
      "idOficina" : Number(idOfi),
      "idSerie"   : Number(idSer),
      "dia"       : fecha,
      "hora"      : hora,
      "rut"       : rut,
      "mail"      : mail,
      "telefono"  : fono
    };
    try {
      return await this.httpClient.post(apiUrl + 'agendar', body, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }


  async listarReservas(rut: string){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      let response =  await this.token.getToken();
      if (response['tokenid']!== '-1'){
        sessionStorage.setItem('token', response['tokenid'])
      }
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    try {
      return await this.httpClient.get(apiUrl + `Reservas?Rut=${rut}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }

  async anularReserva(rut: string, idSer: string, idOfi: string, dia: string, hora: string){
    let bearerToken = "";
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === "") {
      this.token.getToken()
                .then(data => sessionStorage.setItem('token', data['tokenid']));
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
      'Authorization' : 'Bearer ' + sessionStorage.getItem('token')

    });
    const apiUrl = await this.config.get("apiUrl");
    try {
      return await this.httpClient.get(apiUrl + `Anular?IdOfi=${idOfi}&IdSerie=${idSer}&Dia=${dia}&Hora=${hora}&RutCliente=${rut}`, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'info' : {
          'status'  : false,
          'data'    : 'error al ejecutar petición',
          'coderr' : error.status
        }
      };
      return resultado;
    }
  }


}
