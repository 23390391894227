import { FormGroup, FormControl, Validators } from '@angular/forms';
export class CustomValidator {

  constructor() { }


  //Validador de rut.
  public invalidRut(control: FormControl): { [s: string]: boolean } {
    // Obtenemos el valor de nuestro control
    let rut = control.value;
    //Valido que contenga el guión requerido y que no tenga puntos
    if (!rut.includes("-") || rut.includes(".")) {
      return {
        invalidrut: true
      }
    }
    // Despeja punto de millones
    let valor = rut.replace('.', '');
    // Despeja punto de miles
    valor = valor.replace('.', '');
    // Despejar Guión
    valor = valor.replace('-', '');

    // Aislar Cuerpo y Dígito Verificador
    let cuerpo = valor.slice(0, -1);
    let dv = valor.slice(-1).toUpperCase();

    // Formatear RUN
    rut = cuerpo + '-' + dv

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (cuerpo.length < 7) {
      return {
        invalidrut: true
      }
    }

    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;

    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {

      // Obtener su Producto con el Múltiplo Correspondiente
      let index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

    }

    // Calcular Dígito Verificador en base al Módulo 11
    let dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = (dv == 'K') ? 10 : dv;
    dv = (dv == 0) ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) {
      return {
        invalidrut: true
      }
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
    return null;

  }



  //Validar passwords.
  public passNotMatch(control: FormControl): { [s: string]: boolean } {
    let forma: any = this;
    if (control.value !== forma.controls['password'].value) {
      return {
        notmatch: true
      }
    }
    return null;
  }


 //Validar telefono.
 
  public invalidPhone(control: FormControl): { [s: string]: boolean } {
    // Obtenemos el valor de nuestro control
    let fono = Number(control.value);
    //Valido que contenga el guión requerido y que no tenga puntos
    //977497636
    if (fono.toString().length !== 9 ) {
      return {
        invalidPhone: true
      }
    }
    if (fono < 900000000 || fono > 999999999){
    return {
        invalidPhone: true
      }
    }
    // Si todo sale bien, eliminar errores (decretar que es válido)
    return null;
  }


  //Validador de Fechas
  public validaFechaDDMMAAAA = (control: FormControl): { [s: string]: boolean } => {
  let dtCh = "/";
  let minYear = 1900;
  let maxYear = 2100;

      if(control.value){
        if (this.isDate(control.value,dtCh,minYear,maxYear)) {
          return null;
        } else {
          return {invalidDate: true};
        }
      }

  }
  /*Validadores internos para la validación de fecha*/
   private isInteger(s) {
    let i;
    for (i = 0; i < s.length; i++) {
      let c = s.charAt(i);
      if (((c < "0") || (c > "9"))) return false;
    }
    return true;
  }
   private stripCharsInBag(s, bag) {
    let i;
    let returnString = "";
    for (i = 0; i < s.length; i++) {
      let c = s.charAt(i);
      if (bag.indexOf(c) == -1) returnString += c;
    }
    return returnString;
  }
   private daysInFebruary(year) {
    return (((year % 4 == 0) && ((!(year % 100 == 0)) || (year % 400 == 0))) ? 29 : 28);
  }
   private DaysArray(n) {
    for (let i = 1; i <= n; i++) {
      this[i] = 31
      if (i == 4 || i == 6 || i == 9 || i == 11) { this[i] = 30 }
      if (i == 2) { this[i] = 29 }
    }
    return this
  }
  private isDate(dtStr, dtCh, minYear, maxYear) {
    let daysInMonth = this.DaysArray(12)
    let pos1 = dtStr.indexOf(dtCh)
    let pos2 = dtStr.indexOf(dtCh, pos1 + 1)
    let strDay = dtStr.substring(0, pos1)
    let strMonth = dtStr.substring(pos1 + 1, pos2)
    let strYear = dtStr.substring(pos2 + 1)
    let strYr = strYear
    if (strDay.charAt(0) == "0" && strDay.length > 1) strDay = strDay.substring(1)
        if (strMonth.charAt(0) == "0" && strMonth.length > 1) strMonth = strMonth.substring(1)
            for (let i = 1; i <= 3; i++) {
              if (strYr.charAt(0) == "0" && strYr.length > 1) strYr = strYr.substring(1)
            }
            let month = parseInt(strMonth)
            let day = parseInt(strDay)
            let year = parseInt(strYr)
            if (pos1 == -1 || pos2 == -1) {
              return false
            }
            if (strMonth.length < 1 || month < 1 || month > 12) {
              return false
            }
            if (strDay.length < 1 || day < 1 || day > 31 || (month == 2 && day > this.daysInFebruary(year)) || day > daysInMonth[month]) {
              return false
            }
            if (strYear.length != 4 || year == 0 || year < minYear || year > maxYear) {
              return false
            }
            if (dtStr.indexOf(dtCh, pos2 + 1) != -1 || this.isInteger(this.stripCharsInBag(dtStr, dtCh)) == false) {
              return false
            }
     return true
  }

}
