import { CustomTtpService } from './custom-ttp.service';
import { Injectable } from '@angular/core';
import { AppConfig  } from './../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private config: AppConfig, private httpClient: HttpClient, private customTTP: CustomTtpService) { }

  async getToken(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    const apiUrl    = await this.config.get("apiUrl");
    const userToken = await this.config.get("userToken");
    const passToken = await this.config.get("passToken");
    let tokenBody = {
      'email'     : userToken,
      'password'  : passToken
    };
    try {
      return await this.httpClient.post(apiUrl + 'identity/login', tokenBody, {headers: headers}).toPromise();
    } catch (error) {
      let resultado = {
        'tokenid'  : '-1'
      };
      return resultado;
    }
  }
}
