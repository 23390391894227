import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {
    public config: Object = null;

    constructor(private http: HttpClient) { }

    public get(key: any) {
        this.load();
        return this.config[key];
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.configJson)
            .pipe(catchError((error: any): any => {
            //    console.log('Configuración no encontrada');
                resolve(true);
                return Observable.throw(error.json().error || 'Server error');
            }))
            .subscribe((responseData) => {
                this.config = responseData;
                // console.log(this.config);
                resolve(true);
            });
        });
    }
}
