import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private defaultTitle = '';
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject(
    this.defaultTitle
  );
  public title: Observable<string>;

  constructor(private titleService: Title) {
    this.title = this.titleSubject.asObservable();
  }

  public setTitle(title: string) {
    this.titleSubject.next(title);
  }
}
